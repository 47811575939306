var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.tips !== ""
    ? _c(
        "tooltip",
        [
          _c("template", { slot: "title" }, [_vm._v(_vm._s(_vm.tips))]),
          _c("avatar", { attrs: { size: _vm.avatarSize, src: _vm.src } }),
        ],
        2
      )
    : _c("avatar", { attrs: { size: _vm.avatarSize, src: _vm.src } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }